import * as Popper from '../../../node_modules/@popperjs/core/dist/umd/popper';

// import Alert from '../../../node_modules/bootstrap/js/src/alert'
// import Button from '../../../node_modules/bootstrap/js/src/button'
import Carousel from '../../../node_modules/bootstrap/js/src/carousel';
import Collapse from '../../../node_modules/bootstrap/js/src/collapse';
// import Modal from '../../../node_modules/bootstrap/js/src/modal';
import Tab from '../../../node_modules/bootstrap/js/src/tab';
import Dropdown from '../../../node_modules/bootstrap/js/src/dropdown';
import Offcanvas from '../../../node_modules/bootstrap/js/src/offcanvas';

export default {
  // Alert,
  // Button,
   Carousel,
   Collapse,
  //  Modal,
   Tab,
   Dropdown,
   Offcanvas,
   Popper
  // Popover,
  // ScrollSpy,
  // Toast,
}

$((function () { $(document).scroll((function () { var l = $(".navbar"); l.toggleClass("scrolled", $(this).scrollTop() > l.height()) })) }));

/* var catalogoModal = document.getElementById('catalogoModal')
catalogoModal.addEventListener('show.bs.modal', function (event) {

  var button = event.relatedTarget

  var title = button.getAttribute('data-modal-title')
  var Iframe = button.getAttribute('data-modal-iframe')
  
  var modalTitle = catalogoModal.querySelector('.modal-title');
  var modalIframe = document.getElementById("iframe");
  modalIframe.setAttribute("src", Iframe)
  modalTitle.textContent = 'Catalogo de ' + title;
  ;
  
}); */

var map = document.getElementById('map');
var maplistner = function (e) {
  var frame = document.createElement ('iframe');
  frame.src = this.getAttribute ('data-src');
  map.appenChild (frame);
  map.removeEventListener ("mouseover", maplistner);
};
map.addEventListener ('mouseover', maplistner);

window.addEventListener ('load', 
function(e) {
  setTimeout(function(){
    var map = document.getElementById ('map');
    var frame = document.createElement ('iframe');
    frame.src = map.getAttribute ('data-src');
    map.appendChild (frame);
  }, 1500); 
});

var lazyLoadInstance = new LazyLoad({
  // Your custom settings go here
});
